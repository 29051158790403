import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import './styles/lojas.scss'
import { graphql, Link } from 'gatsby'
import NoScript from 'react-noscript'
// components
import Locator from '../components/Locator/Locator'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'

// assets
import buttonMap from '../images/lojas/buttonMap.svg'
import buttonLista from '../images/lojas/buttonLista.svg'
import buttonMapActive from '../images/lojas/buttonMapActive.svg'
import buttonListaActive from '../images/lojas/buttonListaActive.svg'
import buttonMobileMapAc from '../images/lojas/buttonMobileMapAc.svg'
import buttonMobileListaAc from '../images/lojas/buttonMobileListaAc.svg'
import buttonMobileMap from '../images/lojas/buttonMobileMap.svg'
import buttonMobileLista from '../images/lojas/buttonMobileLista.svg'
import iconSearch from '../images/lojas/search.svg'

import lojasNatalAnoNovo from '../utils/lojas_natal_ano_novo.json'

const Pagination = forwardRef((props, ref) => {
  const [pages, setPages] = useState([1, 2, 3])

  useImperativeHandle(ref, () => ({
    goToFirstPage () {
      setPages([1, 2, 3])
    }
  }))

  const showMore = () => {
    if (pages[2] < Math.round(props.markets.length / 6)) {
      const newPages = pages.map(page => page + 1)
      setPages(newPages)
    }
  }

  const showLess = () => {
    if (pages[0] > 1) {
      const newPages = pages.map(page => page - 1)
      setPages(newPages)
    }
  }

  const next = () => {
    const page = props.pagination.active
    props.pagination.setActive(page + 6)
    if (page >= 12) {
      showMore()
    }
  }

  const prev = () => {
    props.pagination.setActive(props.pagination.active - 6)
    if (props.pagination.active >= 6) {
      showLess()
    }
  }

  const handleClick = (currentPagination, currentPage) => {
    props.pagination.setActive(currentPagination)
    if (pages.indexOf(currentPage) === 2) {
      showMore()
    } else if (pages.indexOf(currentPage) === 0) {
      showLess()
    }
  }
  return (
    <>
      <div className="d-flex justify-content-center py-3">
        <button className="bg-transparent border-0 px-4" disabled={pages[0] === 1 && props.pagination.active === 0} alt="Botão Página Anterior"
          onClick={() => prev()}>
          <i className={pages[0] === 1 && props.pagination.active === 0 ? 'arrow-gray arrow-left' : 'arrow-white arrow-left'}></i>
        </button>
        <div className="row justify-content-center lojas-paginador">
          {
            pages.map(page => {
              const currentPagination = (page - 1) * 6
              return (
                <div className="col d-flex justify-content-center" key={page}>
                  <span key={page}><button onClick={() => handleClick(currentPagination, page)} alt="botão para mudar de página"
                    className={(currentPagination === props.pagination.active)
                      ? 'rounded px-3 py-2 text-white btn-water-green border-0' : 'px-3 py-2 border-0 bg-transparent'}
                  >{page}</button></span>
                </div>
              )
            })
          }
        </div>
        <button className="bg-transparent border-0 px-4" disabled={pages[2] >= Math.round(props.markets.length / 6)} alt="Botão Próxima Página" onClick={() => next()}>
          <i className={pages[2] >= Math.round(props.markets.length / 6) ? 'arrow-gray arrow-right' : 'arrow-white arrow-right'}></i>
        </button>
      </div>
    </>
  )
})

Pagination.displayName = 'Pagination'
export default function Lojas ({ data }) {
  const { lojas } = data
  const [markets, setMarkets] = useState(lojas.nodes.map(node => (node)))
  const [page, setPage] = useState(true)
  const [activePage, setActivePage] = useState(0)
  const [currentMarket, setCurrentMarket] = useState(null)
  const [searchName, setSearchName] = useState('Lojas Próximas')
  const [searchResult, setSearchResult] = useState('')
  const childRef = useRef()
  const paginationRef = useRef()
  const breadC = {
    visible: true,
    color: 'black', // Options black || white
    items: [
      { path: '/', name: 'Home' },
      { path: '/lojas/', name: 'Lojas' }
    ]
  }

  useEffect(() => {
    initMaps()
  }, [])

  const initMaps = () => {
    if (typeof window !== 'undefined') {
      if (!window.google) {
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?language=pt-BR&libraries=places&key=${process.env.GATSBY_CREDENTIAL_API_MAPS_LOJAS}`
        const insercao = document.getElementsByTagName('script')[0]
        insercao.parentNode.insertBefore(script, insercao)
        script.addEventListener('load', e => {
          console.log('Geocoder is now available!')
        })

        if (window.localStorage && currentMarket === null) {
          const temp = window.localStorage.getItem('currentMarket')
          setCurrentMarket(JSON.parse(temp))
        }

        useCurrentLocation()
      }
    }
  }

  const useCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        calcDistance(pos)
      })
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({
      address: searchResult,
      region: 'br',
      componentRestrictions: {
        country: 'BR'
      }
    },
    (results, status) => {
      console.log('google results', { results: results, status: status })

      if (status === 'OK') {
        const pos = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        }
        calcDistance(pos)
        if (paginationRef.current) {
          paginationRef.current.goToFirstPage()
        }

        // const searchLabel = [...new Set(results[0].address_components.map(res => res.long_name))].reduce((a, b) => a + ', ' + b)
        setSearchName(`Resultados de Busca para "${results[0].formatted_address}"`)
      } else {
        console.log('FAIL')
      }
    })
  }

  const calcDistance = (p) => {
    console.log('>> CalcDistance')
    const myLat = p.lat
    const myLng = p.lng
    // eslint-disable-next-line no-unused-vars
    let mkts = lojas.nodes.map(node => {
      const LatLoja = node.lat
      const LngLoja = node.lng
      const radlat1 = (Math.PI * myLat) / 180
      const radlat2 = (Math.PI * LatLoja) / 180

      const theta = myLng - LngLoja
      const radtheta = (Math.PI * theta) / 180
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      dist = dist * 1.609344
      node.distance = parseFloat(dist.toFixed(1))
      return node
    })

    mkts = mkts.sort((a, b) => (a.distance - b.distance))
    setMarkets(mkts)
    console.log(mkts)
    setActivePage(0)
    if (childRef.current) {
      childRef.current.currentMarket(mkts[0])
    }
  }

  const handleMarketSelected = (market) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('currentMarket', JSON.stringify(market))
      document.getElementById('currentMarket').innerHTML = market.name
    }
  }

  const existHorario = (lojaSeleciona) => {
    const find = lojasNatalAnoNovo.find((lj) => { return lj.numero === lojaSeleciona.storeNumber })

    return find ? true : false
  }

  const horarioAbertura = (lojaSeleciona) => {
    const find = lojasNatalAnoNovo.find((lj) => { return lj.numero === lojaSeleciona.storeNumber })

    return find ? find.horario_dia_31_abertura : lojaSeleciona.sundayOpen.substr(0, 5)
  }

  const horarioFechamento = (lojaSeleciona) => {
    const find = lojasNatalAnoNovo.find((lj) => { return lj.numero === lojaSeleciona.storeNumber })

    return find ? find.horario_dia_31_fechamento : lojaSeleciona.sundayClose.substr(0, 5)
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Lojas - Dia Supermercados</title>
        <meta name="title" content="Lojas - Dia Supermercados" />
        <meta name="description" content="Escolha uma loja DIA e confira todas as ofertas que estão pertinho de você." />
        <meta name="keywords" content="endereco loja dia, dia supermercado, ofertas dia" />
        <meta name="author" content="DIA Supermercados" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Lojas - Dia Supermercados" />
        <meta property="og:description" content="Escolha uma loja DIA e confira todas as ofertas que estão pertinho de você." />
      </Helmet>
      <NoScript>
        <h1>Lojas do DIA Supermercados Perto de Você</h1>
        <ul>
          {
            markets.map((loja) => {
              return (
                <li key={loja.id}><a href={'/lojas/' + loja.slug + '/'}>{loja.storeNumber + ' - ' + loja.name}</a></li>
              )
            })
          }
        </ul>
      </NoScript>

      <section>
        <div className="position-relative" style={{ minheight: '200px' }}>
          <GatsbyImage
            image={data.bannerLojas.childImageSharp.gatsbyImageData}
            className="position-absolute w-100 h-100"
            alt="banner de lojas" />
          <div className="container pt-lg-0 pt-5  ">
            <div className="row d-flex justify-content-center align-items-lg-center bannerLojasPesquisa">
              <div className="col-lg-5 col-12 text-lg-left text-center">
                <h2 className="text-blue text-lojas">Tem sempre uma loja com super ofertas pertinho de você</h2>
              </div>
              <div className="col-lg-7 col-12">
                <form onSubmit={(e) => handleSearch(e)} className="d-flex ">
                  <input type="text" className="inputPesquisa w-100 rounded-left bg-white text-dark font-size-1" placeholder="Digite seu Endereço/CEP" onChange={(e) => setSearchResult(e.target.value)} value={searchResult} style={{ outline: 'none' }}/>
                  <button className="bg-white rounded-right border-0 d-lg-block d-none" style={{ outline: 'none' }}>
                    <img src={iconSearch} className="pr-3" alt="icone de busca" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row pl-3">
            <div className="col-lg-9 col-7 mt-5 mb-5 p-0">
              <h2 className="text-lojas">{searchName}</h2>
            </div>
            <div className="col-3 mt-5 mb-5 d-lg-flex d-none align-items-center justify-content-end">
              <a className="cursor-pointer" onClick={() => setPage(true)}><img src={page ? buttonListaActive : buttonLista } className="mr-2" alt="button list" /></a>
              <a className="cursor-pointer" onClick={() => setPage(false)}><img src={page ? buttonMap : buttonMapActive } alt="button map" /></a>
            </div>
            <div className="col-5 mt-5 mb-5 d-lg-none d-flex align-items-center justify-content-end">
              <a className="cursor-pointer" onClick={() => setPage(true)}><img src={page ? buttonMobileListaAc : buttonMobileLista } alt="button list" /></a>
              <a className="cursor-pointer" onClick={() => setPage(false)}><img src={page ? buttonMobileMap : buttonMobileMapAc } alt="button map" /></a>
            </div>
          </div>
        </div>
        {page
          ? <div className="container">
            <div className="row">
              {
                markets.slice(activePage, activePage + 6).map((loja, index) => {
                  return (
                    <div key={index} className="col-lg-6 col-12 mb-5">
                      <div className="card card-lojas border-0">
                        <div className="row">
                          <div className="col-lg-7">
                            <h2 className="text-red font-size-15 mt-0 mb-3">{loja.name}</h2>
                            <p className="font-size-1" style={{ lineHeight: '24px' }}>
                              {loja.address}<br/>
                              {loja.district} - {loja.uf} <br/>
                              CEP {loja.cep} <br/>
                              {loja.distance && <>Distância: {loja.distance} Km</>}
                            </p>
                            <p className="mt-2 font-size-09 text-gray-1">
                              Segunda a Sábado: {loja.mondayOpen.substr(0, 5)} às {loja.mondayClose.substr(0, 5)}<br/>
                              Domingo: {loja.sundayOpen.substr(0, 5)} às {loja.sundayClose.substr(0, 5)}<br/>
                              Feriados: {loja.holidayOpen.substr(0, 5)} às {loja.holidayClose.substr(0, 5)}
                              {/* { existHorario(loja) && `31/12/2023: ${horarioAbertura(loja)} às ${horarioFechamento(loja)}` } */}
                            </p>
                          </div>
                          <div className="col-lg-5 w-100">
                            <div className="row pl-3">
                              {loja.services.length > 0 && <p className="text-gray-1 d-flex align-items-center font-Yanone font-size-12">Serviços</p>}
                              {loja.services.map(servico => {
                                return (
                                  <img
                                    width="30" height="30"
                                    src={servico.icon}
                                    alt={`${servico.name} disponível na loja.`}
                                    className='ml-1'
                                    key={servico.name}
                                    data-toogle="tooltip" title={servico.name} />
                                )
                              })}
                              {loja.services.length === 0 && <span>&nbsp;</span>}
                            </div>
                            <a href={`https://www.google.com/maps/dir/Minha+Localização/${loja.lat},${loja.lng}`}><button type="button" className="mt-4 mb-3 btn btn-water-green-2 w-100 font-Yanone font-size-1">Como chegar</button></a>
                            <Link to={`/lojas/${loja.slug}`} className="mb-3 btn btn-red-1 w-100 font-Yanone p-2 font-size-1" onClick={() => handleMarketSelected(loja)}>Ofertas da semana</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <Pagination markets={markets} pagination={{ active: activePage, setActive: setActivePage }} ref={paginationRef} />
          </div>
          : <Locator markets={markets} currentMarket={currentMarket} ref={childRef} />
        }
      </section>
    </Layout>
  )
}

export const query = graphql`{
  lojas: allCloudOfertasLoja {
    nodes {
      id
      storeNumber
      slug
      address
      cep
      city
      district
      lat
      lng
      name
      reference
      status
      uf
      whatsapp
      mondayOpen
      mondayClose
      holidayOpen
      holidayClose
      sundayOpen
      sundayClose
      services {
        icon
        name
      }
      tariff {
        id
        name
      }
    }
  }
  bannerLojas: file(relativePath: {eq: "lojas/bannerLojas.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
